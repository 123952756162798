// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import { mount, unmount } from 'svelte'
import * as bootstrap from "bootstrap"

import ReadingWidget from './ReadingWidget.svelte';

let rwInstance;

function initReadingWidget() {
  const target = document.getElementById("reading-widget");

  if (!target) return;

  if (rwInstance) {
    unmount(rwInstance)
  }

  const readingData = JSON.parse(target.getAttributeNode('data-reading')?.value ?? "null")

  rwInstance = mount(ReadingWidget, { target, props: { readingData }});
}

document.addEventListener("turbo:before-cache", () => {
  if (rwInstance) {
    unmount(rwInstance)
    rwInstance = null;
  }
});

window.addEventListener('turbo:load', initReadingWidget)
