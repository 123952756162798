// This file was automatically generated.

// Start a poetry shell in backend/ directory, then run:
// cd tarotbot && python3 -c "from cards import generate_typescript; generate_typescript()" && cd -
// to create this file.

import { ITarotCard } from "./types"

export const majorArcana: ITarotCard[] = [
  {
    'id': 'arcana_1',
    'file': 'rws_tarot_00',
    'name': {
      'en': 'The Fool',
      'de': 'Der Narr',
      'es': 'El Loco'
    }
  },
  {
    'id': 'arcana_2',
    'file': 'rws_tarot_01',
    'name': {
      'en': 'The Magician',
      'de': 'Der Magier',
      'es': 'El Mago'
    }
  },
  {
    'id': 'arcana_3',
    'file': 'rws_tarot_02',
    'name': {
      'en': 'The High Priestess',
      'de': 'Die Hohepriesterin',
      'es': 'La Sacerdotisa'
    }
  },
  {
    'id': 'arcana_4',
    'file': 'rws_tarot_03',
    'name': {
      'en': 'The Empress',
      'de': 'Die Kaiserin',
      'es': 'La Emperatriz'
    }
  },
  {
    'id': 'arcana_5',
    'file': 'rws_tarot_04',
    'name': {
      'en': 'The Emperor',
      'de': 'Der Kaiser',
      'es': 'El Emperador'
    }
  },
  {
    'id': 'arcana_6',
    'file': 'rws_tarot_05',
    'name': {
      'en': 'The Hierophant',
      'de': 'Der Hierophant',
      'es': 'El Papa'
    }
  },
  {
    'id': 'arcana_7',
    'file': 'rws_tarot_06',
    'name': {
      'en': 'The Lovers',
      'de': 'Die Liebenden',
      'es': 'Los Enamorados'
    }
  },
  {
    'id': 'arcana_8',
    'file': 'rws_tarot_07',
    'name': {
      'en': 'The Chariot',
      'de': 'Der Wagen',
      'es': 'El Carro'
    }
  },
  {
    'id': 'arcana_9',
    'file': 'rws_tarot_08',
    'name': {
      'en': 'Strength',
      'de': 'Die Kraft',
      'es': 'La Fuerza'
    }
  },
  {
    'id': 'arcana_10',
    'file': 'rws_tarot_09',
    'name': {
      'en': 'The Hermit',
      'de': 'Der Eremit',
      'es': 'El Ermita\u00f1o'
    }
  },
  {
    'id': 'arcana_11',
    'file': 'rws_tarot_10',
    'name': {
      'en': 'Wheel of Fortune',
      'de': 'Das Rad des Schicksals',
      'es': 'La Rueda de la Fortuna'
    }
  },
  {
    'id': 'arcana_12',
    'file': 'rws_tarot_11',
    'name': {
      'en': 'Justice',
      'de': 'Die Gerechtigkeit',
      'es': 'La Justicia'
    }
  },
  {
    'id': 'arcana_13',
    'file': 'rws_tarot_12',
    'name': {
      'en': 'The Hanged Man',
      'de': 'Der Geh\u00e4ngte',
      'es': 'El Colgado'
    }
  },
  {
    'id': 'arcana_14',
    'file': 'rws_tarot_13',
    'name': {
      'en': 'Death',
      'de': 'Der Tod',
      'es': 'La Muerte'
    }
  },
  {
    'id': 'arcana_15',
    'file': 'rws_tarot_14',
    'name': {
      'en': 'Temperance',
      'de': 'Die M\u00e4\u00dfigkeit',
      'es': 'La Templanza'
    }
  },
  {
    'id': 'arcana_16',
    'file': 'rws_tarot_15',
    'name': {
      'en': 'The Devil',
      'de': 'Der Teufel',
      'es': 'El Diablo'
    }
  },
  {
    'id': 'arcana_17',
    'file': 'rws_tarot_16',
    'name': {
      'en': 'The Tower',
      'de': 'Der Turm',
      'es': 'La Torre'
    }
  },
  {
    'id': 'arcana_18',
    'file': 'rws_tarot_17',
    'name': {
      'en': 'The Star',
      'de': 'Der Stern',
      'es': 'La Estrella'
    }
  },
  {
    'id': 'arcana_19',
    'file': 'rws_tarot_18',
    'name': {
      'en': 'The Moon',
      'de': 'Der Mond',
      'es': 'La Luna'
    }
  },
  {
    'id': 'arcana_20',
    'file': 'rws_tarot_19',
    'name': {
      'en': 'The Sun',
      'de': 'Die Sonne',
      'es': 'El Sol'
    }
  },
  {
    'id': 'arcana_21',
    'file': 'rws_tarot_20',
    'name': {
      'en': 'Judgment',
      'de': 'Das Gericht',
      'es': 'El Juicio'
    }
  },
  {
    'id': 'arcana_22',
    'file': 'rws_tarot_21',
    'name': {
      'en': 'The World',
      'de': 'Die Welt',
      'es': 'El Mundo'
    }
  }
];

export const minorArcana: ITarotCard[] = [
  {
    'id': 'wands_0',
    'file': 'wands01',
    'name': {
      'en': 'Ace of Wands',
      'de': 'Ass der St\u00e4be',
      'es': 'As de Bastos'
    }
  },
  {
    'id': 'wands_1',
    'file': 'wands02',
    'name': {
      'en': 'Two of Wands',
      'de': 'Zwei der St\u00e4be',
      'es': 'Dos de Bastos'
    }
  },
  {
    'id': 'wands_2',
    'file': 'wands03',
    'name': {
      'en': 'Three of Wands',
      'de': 'Drei der St\u00e4be',
      'es': 'Tres de Bastos'
    }
  },
  {
    'id': 'wands_3',
    'file': 'wands04',
    'name': {
      'en': 'Four of Wands',
      'de': 'Vier der St\u00e4be',
      'es': 'Cuatro de Bastos'
    }
  },
  {
    'id': 'wands_4',
    'file': 'wands05',
    'name': {
      'en': 'Five of Wands',
      'de': 'F\u00fcnf der St\u00e4be',
      'es': 'Cinco de Bastos'
    }
  },
  {
    'id': 'wands_5',
    'file': 'wands06',
    'name': {
      'en': 'Six of Wands',
      'de': 'Sechs der St\u00e4be',
      'es': 'Seis de Bastos'
    }
  },
  {
    'id': 'wands_6',
    'file': 'wands07',
    'name': {
      'en': 'Seven of Wands',
      'de': 'Sieben der St\u00e4be',
      'es': 'Siete de Bastos'
    }
  },
  {
    'id': 'wands_7',
    'file': 'wands08',
    'name': {
      'en': 'Eight of Wands',
      'de': 'Acht der St\u00e4be',
      'es': 'Ocho de Bastos'
    }
  },
  {
    'id': 'wands_8',
    'file': 'wands09',
    'name': {
      'en': 'Nine of Wands',
      'de': 'Neun der St\u00e4be',
      'es': 'Nueve de Bastos'
    }
  },
  {
    'id': 'wands_9',
    'file': 'wands10',
    'name': {
      'en': 'Ten of Wands',
      'de': 'Zehn der St\u00e4be',
      'es': 'Diez de Bastos'
    }
  },
  {
    'id': 'wands_10',
    'file': 'wands11',
    'name': {
      'en': 'Page of Wands',
      'de': 'Bube der St\u00e4be',
      'es': 'Sota de Bastos'
    }
  },
  {
    'id': 'wands_11',
    'file': 'wands12',
    'name': {
      'en': 'Knight of Wands',
      'de': 'Ritter der St\u00e4be',
      'es': 'Caballero de Bastos'
    }
  },
  {
    'id': 'wands_12',
    'file': 'wands13',
    'name': {
      'en': 'Queen of Wands',
      'de': 'K\u00f6nigin der St\u00e4be',
      'es': 'Reina de Bastos'
    }
  },
  {
    'id': 'wands_13',
    'file': 'wands14',
    'name': {
      'en': 'King of Wands',
      'de': 'K\u00f6nig der St\u00e4be',
      'es': 'Rey de Bastos'
    }
  },
  {
    'id': 'cups_0',
    'file': 'cups01',
    'name': {
      'en': 'Ace of Cups',
      'de': 'Ass der Kelche',
      'es': 'As de Copas'
    }
  },
  {
    'id': 'cups_1',
    'file': 'cups02',
    'name': {
      'en': 'Two of Cups',
      'de': 'Zwei der Kelche',
      'es': 'Dos de Copas'
    }
  },
  {
    'id': 'cups_2',
    'file': 'cups03',
    'name': {
      'en': 'Three of Cups',
      'de': 'Drei der Kelche',
      'es': 'Tres de Copas'
    }
  },
  {
    'id': 'cups_3',
    'file': 'cups04',
    'name': {
      'en': 'Four of Cups',
      'de': 'Vier der Kelche',
      'es': 'Cuatro de Copas'
    }
  },
  {
    'id': 'cups_4',
    'file': 'cups05',
    'name': {
      'en': 'Five of Cups',
      'de': 'F\u00fcnf der Kelche',
      'es': 'Cinco de Copas'
    }
  },
  {
    'id': 'cups_5',
    'file': 'cups06',
    'name': {
      'en': 'Six of Cups',
      'de': 'Sechs der Kelche',
      'es': 'Seis de Copas'
    }
  },
  {
    'id': 'cups_6',
    'file': 'cups07',
    'name': {
      'en': 'Seven of Cups',
      'de': 'Sieben der Kelche',
      'es': 'Siete de Copas'
    }
  },
  {
    'id': 'cups_7',
    'file': 'cups08',
    'name': {
      'en': 'Eight of Cups',
      'de': 'Acht der Kelche',
      'es': 'Ocho de Copas'
    }
  },
  {
    'id': 'cups_8',
    'file': 'cups09',
    'name': {
      'en': 'Nine of Cups',
      'de': 'Neun der Kelche',
      'es': 'Nueve de Copas'
    }
  },
  {
    'id': 'cups_9',
    'file': 'cups10',
    'name': {
      'en': 'Ten of Cups',
      'de': 'Zehn der Kelche',
      'es': 'Diez de Copas'
    }
  },
  {
    'id': 'cups_10',
    'file': 'cups11',
    'name': {
      'en': 'Page of Cups',
      'de': 'Bube der Kelche',
      'es': 'Sota de Copas'
    }
  },
  {
    'id': 'cups_11',
    'file': 'cups12',
    'name': {
      'en': 'Knight of Cups',
      'de': 'Ritter der Kelche',
      'es': 'Caballero de Copas'
    }
  },
  {
    'id': 'cups_12',
    'file': 'cups13',
    'name': {
      'en': 'Queen of Cups',
      'de': 'K\u00f6nigin der Kelche',
      'es': 'Reina de Copas'
    }
  },
  {
    'id': 'cups_13',
    'file': 'cups14',
    'name': {
      'en': 'King of Cups',
      'de': 'K\u00f6nig der Kelche',
      'es': 'Rey de Copas'
    }
  },
  {
    'id': 'swords_0',
    'file': 'swords01',
    'name': {
      'en': 'Ace of Swords',
      'de': 'Ass der Schwerter',
      'es': 'As de Espadas'
    }
  },
  {
    'id': 'swords_1',
    'file': 'swords02',
    'name': {
      'en': 'Two of Swords',
      'de': 'Zwei der Schwerter',
      'es': 'Dos de Espadas'
    }
  },
  {
    'id': 'swords_2',
    'file': 'swords03',
    'name': {
      'en': 'Three of Swords',
      'de': 'Drei der Schwerter',
      'es': 'Tres de Espadas'
    }
  },
  {
    'id': 'swords_3',
    'file': 'swords04',
    'name': {
      'en': 'Four of Swords',
      'de': 'Vier der Schwerter',
      'es': 'Cuatro de Espadas'
    }
  },
  {
    'id': 'swords_4',
    'file': 'swords05',
    'name': {
      'en': 'Five of Swords',
      'de': 'F\u00fcnf der Schwerter',
      'es': 'Cinco de Espadas'
    }
  },
  {
    'id': 'swords_5',
    'file': 'swords06',
    'name': {
      'en': 'Six of Swords',
      'de': 'Sechs der Schwerter',
      'es': 'Seis de Espadas'
    }
  },
  {
    'id': 'swords_6',
    'file': 'swords07',
    'name': {
      'en': 'Seven of Swords',
      'de': 'Sieben der Schwerter',
      'es': 'Siete de Espadas'
    }
  },
  {
    'id': 'swords_7',
    'file': 'swords08',
    'name': {
      'en': 'Eight of Swords',
      'de': 'Acht der Schwerter',
      'es': 'Ocho de Espadas'
    }
  },
  {
    'id': 'swords_8',
    'file': 'swords09',
    'name': {
      'en': 'Nine of Swords',
      'de': 'Neun der Schwerter',
      'es': 'Nueve de Espadas'
    }
  },
  {
    'id': 'swords_9',
    'file': 'swords10',
    'name': {
      'en': 'Ten of Swords',
      'de': 'Zehn der Schwerter',
      'es': 'Diez de Espadas'
    }
  },
  {
    'id': 'swords_10',
    'file': 'swords11',
    'name': {
      'en': 'Page of Swords',
      'de': 'Bube der Schwerter',
      'es': 'Sota de Espadas'
    }
  },
  {
    'id': 'swords_11',
    'file': 'swords12',
    'name': {
      'en': 'Knight of Swords',
      'de': 'Ritter der Schwerter',
      'es': 'Caballero de Espadas'
    }
  },
  {
    'id': 'swords_12',
    'file': 'swords13',
    'name': {
      'en': 'Queen of Swords',
      'de': 'K\u00f6nigin der Schwerter',
      'es': 'Reina de Espadas'
    }
  },
  {
    'id': 'swords_13',
    'file': 'swords14',
    'name': {
      'en': 'King of Swords',
      'de': 'K\u00f6nig der Schwerter',
      'es': 'Rey de Espadas'
    }
  },
  {
    'id': 'pents_0',
    'file': 'pents01',
    'name': {
      'en': 'Ace of Pentacles',
      'de': 'Ass der M\u00fcnzen',
      'es': 'As de Oros'
    }
  },
  {
    'id': 'pents_1',
    'file': 'pents02',
    'name': {
      'en': 'Two of Pentacles',
      'de': 'Zwei der M\u00fcnzen',
      'es': 'Dos de Oros'
    }
  },
  {
    'id': 'pents_2',
    'file': 'pents03',
    'name': {
      'en': 'Three of Pentacles',
      'de': 'Drei der M\u00fcnzen',
      'es': 'Tres de Oros'
    }
  },
  {
    'id': 'pents_3',
    'file': 'pents04',
    'name': {
      'en': 'Four of Pentacles',
      'de': 'Vier der M\u00fcnzen',
      'es': 'Cuatro de Oros'
    }
  },
  {
    'id': 'pents_4',
    'file': 'pents05',
    'name': {
      'en': 'Five of Pentacles',
      'de': 'F\u00fcnf der M\u00fcnzen',
      'es': 'Cinco de Oros'
    }
  },
  {
    'id': 'pents_5',
    'file': 'pents06',
    'name': {
      'en': 'Six of Pentacles',
      'de': 'Sechs der M\u00fcnzen',
      'es': 'Seis de Oros'
    }
  },
  {
    'id': 'pents_6',
    'file': 'pents07',
    'name': {
      'en': 'Seven of Pentacles',
      'de': 'Sieben der M\u00fcnzen',
      'es': 'Siete de Oros'
    }
  },
  {
    'id': 'pents_7',
    'file': 'pents08',
    'name': {
      'en': 'Eight of Pentacles',
      'de': 'Acht der M\u00fcnzen',
      'es': 'Ocho de Oros'
    }
  },
  {
    'id': 'pents_8',
    'file': 'pents09',
    'name': {
      'en': 'Nine of Pentacles',
      'de': 'Neun der M\u00fcnzen',
      'es': 'Nueve de Oros'
    }
  },
  {
    'id': 'pents_9',
    'file': 'pents10',
    'name': {
      'en': 'Ten of Pentacles',
      'de': 'Zehn der M\u00fcnzen',
      'es': 'Diez de Oros'
    }
  },
  {
    'id': 'pents_10',
    'file': 'pents11',
    'name': {
      'en': 'Page of Pentacles',
      'de': 'Bube der M\u00fcnzen',
      'es': 'Sota de Oros'
    }
  },
  {
    'id': 'pents_11',
    'file': 'pents12',
    'name': {
      'en': 'Knight of Pentacles',
      'de': 'Ritter der M\u00fcnzen',
      'es': 'Caballero de Oros'
    }
  },
  {
    'id': 'pents_12',
    'file': 'pents13',
    'name': {
      'en': 'Queen of Pentacles',
      'de': 'K\u00f6nigin der M\u00fcnzen',
      'es': 'Reina de Oros'
    }
  },
  {
    'id': 'pents_13',
    'file': 'pents14',
    'name': {
      'en': 'King of Pentacles',
      'de': 'K\u00f6nig der M\u00fcnzen',
      'es': 'Rey de Oros'
    }
  }
];

