<script lang="ts">
  // import { parseResults } from './utils';
  import { ResultsParser, findTarotCardByName } from './utils';
  import { onMount } from 'svelte';
  import TarotInput from './TarotInput.svelte';

  let { readingData } = $props();

  let question = $state('')
  let lastQuestion = $state('')
  let busy = $state(false)
  let content = $state('')
  let readingComplete = $state(false)

  let intro = $state('')
  let conclusion = $state('')
  let cards = $state([])

  let displayMode = $state('')
  let signupRequired = $derived(displayMode === 'signupRequired')
  let creditsRequired = $derived(displayMode === 'creditsRequired')

  let readingCompleted = $state(false)

  function handleInputChange(event) {
    question = event.target.value;
  }

  function submit(e) {
    lastQuestion = question // `${$state.snapshot(question)}`
    busy = true
    hideNonReadingElements()
    consumeStream()
    question = ''
  }

  const cbs = {
    setCompletion: (isComplete: boolean) => {
      readingCompleted = isComplete
    },
    setDisplayMode: (newMode: string) => {
      displayMode = newMode
    },
    setIntro: (content: string) => {
      intro = content;
    },
    setConclusion: (content: string) => {
      conclusion = content;
    },
    addCard: (cardName: string, content: string) => {
      const cdata = findTarotCardByName(cardName)
      cards.push({ name: cardName, content, imgSrc: `/cards/${cdata.file}.webp` })
    }
  }

  async function consumeStream() {
    try {
      const token = document.querySelector('meta[name="csrf-token"]').content;
      const request = new Request("/readings", {
        headers: {
          "X-CSRF-Token": token,
          "Content-Type": "application/json"
        },
        method: "POST",
        body: JSON.stringify({ question })
        // redirect: 'manual'
      });
      const response = await fetch(request);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const parser = new ResultsParser(cbs)

      let buffer = ''
      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');
      let receivedText = '';

      while (true) {
        const { value, done } = await reader.read();

        if (done) {
          parser.finish()
          busy = false
          break;
        }

        const chunk = decoder.decode(value, { stream: true });
        parser.update(chunk);
        receivedText += chunk
      }
    } catch (error) {
      console.error('Error consuming stream:', error);
    }
  }

  function hideNonReadingElements () {
    document.querySelectorAll('.hide-when-reading').forEach(el => {
        el.style.display = 'none';
    })
  }

  onMount(() => {
    if (readingData != null) {
      const parser = new ResultsParser(cbs)
      lastQuestion = readingData.question
      parser.update(readingData._output);
      parser.finish()
    }
  })
</script>

<div class="position-relative">
  {#if intro.length > 0}
  <div class="card mb-2">
    <div class="card-body">
      <p class="muted fst-italic">"{lastQuestion}"</p>
      <p class="card-text">{intro}</p>
    </div>
  </div>
  {/if}

  {#each cards as card}
  <div class="card mb-2">
    <div class="card-header">
      {card.name}
    </div>
    <div class="card-body">
      <img src={card.imgSrc} />
      <p class="card-text">{card.content}</p>
    </div>
  </div>
  {/each}

  {#if conclusion.length > 0}
  <div class="card">
    <div class="card-body">
      <h5 class="card-title">Conclusion</h5>
      <p class="card-text">{conclusion}</p>
    </div>
  </div>
  {/if}

  {#if busy}
    <div class='text-center mx-3'>
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  {:else if readingCompleted}
    {#if signupRequired}
      <div class='reading-overlay'>
        Create a free account to continue with your reading!
        <div>
          <a href='/continue' class="btn btn-lg btn-primary mt-2">Click to here to sign up</a>
        </div>
      </div>
    {:else}
      <a href="/" class="btn btn-primary m-2">Click here to do another reading.</a>
    {/if}
  {:else}
    <div class='text-center'>
      <div class='mb-3'>
        <TarotInput value={question} oi={handleInputChange} class="form-control form-control-lg" />
      </div>
      <button onclick={submit} class="btn btn-primary">Ask the Tarot!</button>
    </div>
  {/if}
</div>
