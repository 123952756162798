<script lang="ts">
  import { onMount } from "svelte";

  // let { value, oi, ...props } = $props();
  let props = $props();

  let placeholder = $state("");
  let currentIndex = 0;

  let inputElement = null
  let suggestions = []

  // Typewriter effect
  function typeWriter(text, callback) {
    let i = 0;
    placeholder = ""; // Reset the placeholder

    const interval = setInterval(() => {
      if (i < text.length) {
        placeholder += text[i]; // Add the next character
        i++;
      } else {
        clearInterval(interval);
        setTimeout(() => callback && callback(), 2000); // Wait 1 second before showing next string
      }
    }, 50); // Typing speed
  }

  async function fetchSuggestions() {
    try {
      const response = await fetch('/api/suggestions');
      if (!response.ok) {
        throw new Error(`Error fetching suggestions Status: ${response.status}`);
      }
      const data = await response.json();
      suggestions = data.suggestions;
    } catch (error) {
      console.error('Error fetching suggestions:', error);
    }

    cycleSuggestions()
  }

  function cycleSuggestions() {
    typeWriter(suggestions[currentIndex], () => {
      currentIndex = (currentIndex + 1) % suggestions.length; // Go to the next suggestion
      cycleSuggestions(); // Start typing the next suggestion
    });
  }

  // Start on mount
  onMount(() => {
    inputElement.focus();
    fetchSuggestions();
  });
</script>

<input {...props} value={props.value} oninput={props.oi} bind:this={inputElement} type="text" placeholder={placeholder} />
