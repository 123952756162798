import { majorArcana } from './card_data'
import { ITarotCard } from "./types"

export function findTarotCardByName(cardName: string): ITarotCard {
  // Loop through the array and find the first card that matches the name
  const result = majorArcana.find(card =>
    card.name.en.toLowerCase() === cardName.toLowerCase() ||
    card.name.de.toLowerCase() === cardName.toLowerCase() ||
    card.name.es.toLowerCase() === cardName.toLowerCase()
  );

  if (result == undefined) {
    // TODO:
    //
    // card not found Judgement
    //
    // throw new Error('Card not found.' + cardName)
    console.warn('card not found', cardName)
    return majorArcana[0]
  }

  return result
}


interface CbFns {
  setDisplayMode: (newMode: string) => void
  setIntro: (content: string) => void
  setConclusion: (content: string) => void
  addCard: (cardName: string, content: string) => void
  setCompletion: (isComplete: boolean) => void
}

export function navigateTo(url: string): void {
  if (window.Turbo) {
    Turbo.visit(url);
  } else {
    window.location.href = url;
  }
}

export class ResultsParser {
  mode: string = 'none'
  lastCard: string = ''
  currentLine: string = ''
  intro: string[] = []
  conclusion: string[] = []
  currentCardContent: string[]
  cb: CbFns

  constructor(callbacks: CbFns) {
    this.cb = callbacks
    this.mode = 'none';
    this.lastCard = '';
    this.currentLine = '';
    this.intro = [];
    this.conclusion = [];
    this.currentCardContent = [];
  }

  processLine(line: string) {
    if (line.startsWith('REDIRECT:')) {
      navigateTo(line.split(':', 2)[1])
      return;
    }
    if (line.startsWith('OPTIONS:')) {
      const opts = JSON.parse(line.substring('OPTIONS:'.length))
      if (opts.url) { history.pushState(null, '', opts.url) }
      if (opts.title) { document.title = opts.title }
      if (opts.displayMode) { this.cb.setDisplayMode(opts.displayMode) }
    }

    // If we're switching to a new section, handle any accumulated card content
    if (line.startsWith('SECTION:') && this.mode === 'card' && this.currentCardContent.length > 0) {
      this.cb.addCard(this.lastCard, this.currentCardContent.join('\n'));
      this.currentCardContent = [];
    }

    // Process content for current mode
    if (!line.startsWith('SECTION:')) {
      switch (this.mode) {
        case 'intro':
          this.intro.push(line);
          break;
        case 'conclusion':
          this.conclusion.push(line);
          break;
        case 'card':
          this.currentCardContent.push(line);
          break;
      }
    }

    // Update mode based on section markers
    if (line === 'SECTION:intro') {
      this.mode = 'intro';
      if (this.intro.length > 0) {
        this.cb.setIntro(this.intro.join('\n'));
        this.intro = [];
      }
    } else if (line === 'SECTION:conclusion') {
      this.mode = 'conclusion';
    } else if (line.startsWith('SECTION:')) {
      if (this.mode === 'intro') {
        if (this.intro.length > 0) {
          this.cb.setIntro(this.intro.join('\n'));
          this.intro = [];
        }
      }
      this.mode = 'card';
      this.lastCard = line.split(':')[1];
    }
  }

  // Process a chunk of data that might contain partial lines
  update(chunk: string) {
    // Convert chunk to string if it's a Buffer
    const data = chunk.toString();

    this.currentLine += data;

    const lines = this.currentLine.split('\n');

    // Keep the last element as it might be incomplete
    this.currentLine = lines.pop() || '';

    // Process all complete lines
    lines.forEach(line => this.processLine(line));
  }

  // Call this when you're done sending chunks to handle any remaining data
  finish() {
    // Process any remaining partial line
    if (this.currentLine) {
      this.processLine(this.currentLine);
      this.currentLine = '';
    }

    // Handle any remaining content
    if (this.mode === 'intro' && this.intro.length > 0) {
        this.cb.setIntro(this.intro.join('\n'));
        this.intro = [];
    }
    if (this.mode === 'card' && this.currentCardContent.length > 0) {
      this.cb.addCard(this.lastCard, this.currentCardContent.join('\n'));
      this.currentCardContent = [];
    }
    if (this.conclusion.length > 0) {
      this.cb.setConclusion(this.conclusion.join('\n'));
      this.conclusion = [];
    }
    this.cb.setCompletion(true)
  }
}

export function parseResults(result: string, cbs: CbFns): void {
  let mode = 'none';
  let lastCard = '';
  let intro: string[] = [];
  let conclusion: string[] = [];
  let currentCardContent: string[] = [];

  result.split('\n').forEach(line => {
    // If we're switching to a new section, handle any accumulated card content
    if (line.startsWith('SECTION:') && mode === 'card' && currentCardContent.length > 0) {
      cbs.addCard(lastCard, currentCardContent.join('\n'));
      currentCardContent = [];
    }

    // Process content for current mode
    if (!line.startsWith('SECTION:')) {
      switch (mode) {
        case 'intro':
          intro.push(line);
          break;
        case 'conclusion':
          conclusion.push(line);
          break;
        case 'card':
          currentCardContent.push(line);
          break;
      }
    }

    // Update mode based on section markers
    if (line === 'SECTION:intro') {
      mode = 'intro';
    } else if (line === 'SECTION:conclusion') {
      mode = 'conclusion';
    } else if (line.startsWith('SECTION:')) {
      mode = 'card';
      lastCard = line.split(':')[1];
    }
  });

  // Handle any remaining content
  if (mode === 'card' && currentCardContent.length > 0) {
    cbs.addCard(lastCard, currentCardContent.join('\n'));
  }
  if (intro.length > 0) {
    cbs.setIntro(intro.join('\n'));
  }
  if (conclusion.length > 0) {
    cbs.setConclusion(conclusion.join('\n'));
  }
}
